export const PromiseGenesys = (command, action, payload) => {
  return new Promise((resolve) => {
    window.Genesys &&
      window.Genesys(
        command,
        action,
        payload,
        (response) => {
          setTimeout(() => resolve(response), 500)
        },
        (err) => {
          console.error(err)
        }
      )
  })
}

export const initWebChat = async (entryPoint: string) => {
  await PromiseGenesys('command', 'database.set', {
    messaging: {
      customAttributes: {
        chatURL: document.location.href,
        isAuthenticated: isAuthenticated(),
        entryPoint: entryPoint,
      },
    },
  })
  await PromiseGenesys('command', 'Messenger.open', {})
}

const isAuthenticated = () => {
  const state = JSON.parse(localStorage.getItem('state') as string)

  return !!state?.auth?.jwt
}
