/*
 * Simple function to interpolate (use placeholders to inject variables into a string).
 * This is useful if we have a static text from some persisted source (e.g. Contentful),
 * and some dynamic values required in this text.
 *
 */
export const processVars = (
  content: string,
  variables: { [key: string]: string }
) =>
  content.replace(/%(\S+)%/g, (match, variableName) => {
    if (variables[variableName]) return variables[variableName]
    else return ''
  })
